<template>
 <div>
  <ExpertStatisticsTemplate
   :periodSelector="periodSelector"
   @timeSelector="selectedtime = $event"
   :elements="extensions"
   :clearTimeSelector="clearTimeSelector"
   @selector="
    $emit('update:selector', $event),
     $emit('setSelector', $event),
     $store.dispatch('pbxPeriodSelected', $event)
   "
   @setPeriod="(...args) => setPeriod(...args)">
   <template v-slot:selector>
    <PbxDashboardSelector
     v-if="callerNumbers"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="
      $emit('action', $event),
       (selectedElement = $event),
       getCallerNumber($event)
     "
     :selectHostname="selectHostname"
     :didNumbers="callerNumbers"
     @selectTop="showTop = $event"
     :selectedTop="showTop"
     :buttonSelected="this.buttonSelected"
     :elements="null" />
   </template>
   <template v-slot:content>
    <PbxHeader
     v-model:search="search"
     pbxMenuType="queueDetail"
     :periodSelector="period"
     :parseDateRange="parseDateRange"
     @select-hostname="selectHostname = $event"
     :selectedQueue="selectedQueue" />
    <div
     v-if="callerNumberDetail && callerNumberDetail.length > 0"
     class="flex flex-col mx-2">
     <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-5">
       <dl class="grid grid-cols-1 gap-2 sm:grid-cols-2 pb-5">
        <h3 class="col-span-2 text-base font-semibold leading-6 text-gray-900">
         <!-- {{ $t("summaryData") }} -->
        </h3>
        <div
         v-for="(stat, key) in sumarryData"
         :key="key"
         class="rounded-lg flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-5 shadow sm:p-4">
         <dt class="text-sm font-medium text-gray-500">
          {{ $t(getTitle(stat)) }}
         </dt>
         <dd
          :class="[
           calculatePercentage(stat[getTitle(stat)], stat.total) <= 20
            ? 'text-red-600'
            : 'text-gray-700',
           'text-xs font-medium',
          ]"
          v-if="stat.total">
          {{
           stat.total
            ? calculatePercentage(stat[getTitle(stat)], stat.total)
            : ""
          }}%
         </dd>
         <dd
          class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {{
           getTitle(stat) === "talking_duration"
            ? formatSecsToHourMinAndSecs(stat[getTitle(stat)])
            : stat[getTitle(stat)]
          }}
          <span
           v-if="stat.total"
           class="ml-2 text-sm font-medium text-gray-500">
           {{ $t("fromTotalCalls", { total: stat.total }) }}
          </span>
         </dd>
        </div>
       </dl>
       <ThirdPartyNumberAnalyser :tablePaginatedData="callerNumberDetail" />
      </div>
     </div>
    </div>
    <div v-else class="pt-10">
     <div class="mx-auto max-w-lg">
      <div>
       <div class="text-center">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke-width="1.5"
         stroke="currentColor"
         class="mx-auto h-12 w-12 text-gray-400">
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
        </svg>

        <h2 class="mt-2 text-base font-semibold leading-6 text-gray-900">
         {{ $t("noCallersForThisPeriod") }}
        </h2>
        <p class="mt-1 text-sm text-gray-500">
         {{ $t("noCallersForThisPeriodDetail") }}
        </p>
       </div>
      </div>
     </div>
    </div>
   </template>
  </ExpertStatisticsTemplate>
 </div>
</template>

<script>
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import ThirdPartyNumberAnalyser from "../../components/PBX/ThirdPartyNumberAnalyser.vue";
import axios from "axios";
import BasicBarChart from "../../components/PBX/BasicBarChart.vue";

import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import { mapGetters } from "vuex";

export default {
 components: {
  PbxHeader,
  PbxDashboardSelector,
  TablePagination,
  BasicBarChart,
  ThirdPartyNumberAnalyser,
  ExpertStatisticsTemplate,
 },
 data() {
  return {
   search: "",
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   trtcData: undefined,
   callerNumbers: [],
   callerNumberDetail: [],
   showTop: "top10",
   textMonth: "",
   buttonSelected: "month",
   callerData: [],
   sumarryData: [],
   categories: [],
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];

   this.getCallerNumbers();
   if (this.selectedElement) {
    this.getCallerNumber(this.selectedElement);
   }
  },
  getTitle(obj) {
   console.log("getTitle ~ obj:", Object.keys(obj)[0]);
   return Object.keys(obj)[0];
  },
  calculatePercentage(count, totalCount) {
   const percentage = (parseInt(count) / parseInt(totalCount)) * 100;
   return parseFloat(percentage.toFixed(2));
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getCallerNumbers() {
   const selectHostname = this.hostName;
   this.$emit("setLoading", true);
   let url = `${localStorage.getItem(
    "apiUrl"
   )}/api/v1.2/${selectHostname}/third-party-numbers-list?start=${
    this.startDate
   }&end=${this.endDate}`;

   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });

    console.log("getCallerNumbers ~ res.data:", res.data);
    this.callerNumbers = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
  async getCallerNumber(number) {
   //  const selectHostname = "beachcombertours.my3cx.fr";
   const selectHostname = this.hostName;
   let url = `${localStorage.getItem(
    "apiUrl"
   )}/api/v1.2/${selectHostname}/third-party-number-history?third_party_number=${number}`;

   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    const dataValuesArray = Object.values(res.data);
    const total = dataValuesArray.reduce(
     (acc, obj) => {
      //   acc.talking_duration += obj.talking_duration;
      acc.total++;
      if (obj.answered) {
       acc.answered++;
      }
      return acc;
     },
     { total: 0, answered: 0 }
    );

    this.sumarryData = [
     //  { talking_duration: total.talking_duration },
     { answered: total.answered, total: total.total },
    ];
    console.log("getCallerNumber ~ this.sumarryData:", this.sumarryData);
    this.callerNumberDetail = dataValuesArray;
    console.log(
     "getCallerNumber ~ this.callerNumberDetail:",
     this.callerNumberDetail
    );
    // this.callerNumberDetail = dataValuesArray.map(obj => {
    //  const dataArr = obj.chain.split(";");
    //  const newData = dataArr.map(str => {
    //   const [element, elementType] = str
    //    .trim()
    //    .split(/ |\(|\)/)
    //    .filter(val => val !== "");
    //   return { element, elementType };
    //  });
    //  return { ...obj, chain: newData };
    // });
   } catch (error) {
    console.log("getCallerNumber ~ error:", error);
    this.errorHandling(error);
   }
  },
  async getDidNumberCallersObj(event) {
   this.$emit("setLoading", true);
   this.callerData = [];
   let data = await this.getCallerNumber(event);
   let objDataTransferred = {};
   let objDataAbandoned = {};
   let queues = [];
   let dataKeys = Object.keys(data);
   dataKeys.forEach(key => {
    let dnType = key.split(":")[2];
    let queueNumber = key.split(":")[3];
    if (dnType == 4) {
     if (!queues.includes(queueNumber)) {
      queues.push(queueNumber);
     }
    }
   });
   let dataArrayTransferred = [0, 0];
   let dataArrayAbandoned = [0, 0];
   for (let index = 0; index < queues.length; index++) {
    const elQueue = queues[index];
    dataKeys.forEach((key, idx) => {
     let queueNumber = key.split(":")[3];
     let keyPeriod = key.split(":")[5];
     let actionType = key.split(":")[6];
     if (queueNumber == elQueue && keyPeriod == this.period) {
      if (actionType == 10) {
       dataArrayTransferred.splice(index, 1, data[key]);
      }
      if (actionType == 5) {
       dataArrayAbandoned.splice(index, 1, data[key]);
      }
     }
    });
   }
   this.categories = queues;
   objDataTransferred["data"] = dataArrayTransferred;
   objDataTransferred["name"] = "transferred";
   objDataAbandoned["data"] = dataArrayAbandoned;
   objDataAbandoned["name"] = "abandoned";
   this.callerData.push(objDataTransferred, objDataAbandoned);

   this.$emit("setLoading", false);
  },
  filteredData() {
   if (this.trtcData) {
    return this.trtcData.data.filter(data => {
     return data.number.toLowerCase().includes(this.search.toLowerCase());
    });
   }
  },
  sortArrObj(arr) {
   let sortedArr = [];

   sortedArr = arr.sort((a, b) => {
    return b.calls - a.calls;
   });

   return sortedArr;
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  currentMonthAndYearForPbx() {
   if (!this.period) {
    let options = { month: "short" };
    let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
    let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
     .format(month)
     .slice(0, -2);
    this.textMonth = formattedMonth;
    let year = new Date().getFullYear();
    this.period = String(year) + String(month);
   }
  },
 },
 watch: {
  // period: function (val) {
  //  this.getCallerNumbers(val);
  //  if (this.selectedElement) {
  //   this.getCallerNumber(this.selectedElement);
  //  }
  // },
 },
 mounted() {
  // this.currentMonthAndYearForPbx();
  // this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
};
</script>

<style></style>
